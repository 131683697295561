declare module 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts' {
    interface IChunks {
        ChunkCRC: number;
        ChunkGuid: string;
        ChunkPosition: number;
        ChunkSize: number;
        Chunk_ID: number;
    }

    interface IFileStoreRecordOptions<T = Blob | ArrayBuffer> {
        primKey: string;
        mimeType: string;
        data: T;
        filename: string;
        filesize: number;
        extension: string;
        pdfRef?: string;
        thumbnailRef?: string;
        originalRef?: string;
        chunks?: Array<IChunks> | null;
        appID: string;
        fileCrc: number | null;
    }

    export class FileStoreRecord {
        static fileStoreRecordDexieSchema: string;

        primKey: string;
        mimeType: string;
        data: ArrayBuffer;
        filename: string;
        filesize: number;
        extension: string;
        pdfRef: string | null;
        thumbnailRef: string | null;
        originalRef: string | null;
        chunks: Array<IChunks> | null;
        appID: string;
        CRC: number | null;

        constructor(options: IFileStoreRecordOptions<ArrayBuffer>);

        static fromBlob(options: IFileStoreRecordOptions<Blob>): Promise<FileStoreRecord>;

        readonly dataAsBlob: Blob;

        save(): Promise<void>;
        delete(): Promise<void>;
    }
}

export {}
